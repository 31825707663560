import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  CreateUserParams,
  LabelsParams,
  UploadParams,
  WatchAnalyticsParams,
  VisitCountsParams,
  addLedgerParams,
  UploadAudioParams,
  UploadThumbnailParams,
} from "./APIParams";
import { APIWrapper, APIResponse } from "./APIWrapper";
import { Config, API, APIAxios } from "../APIAndConfig";
import { get, set, has } from "lodash";
import { Firebase } from "../firebase";

export class APIActions extends APIWrapper {
  async authenticate() {
    return await this.post("/authenticate");
  }
  async getConfig() {
    return await this.get("/client-config", {
      params: {
        environment: Config.Environment,
      },
    });
  }

  async createUser(params: CreateUserParams) {
    return await this.post("/user", { user: params });
  }
  async createUserAbandoned(params: any) {
    return await this.post("/create/UserAbandoned", { user: params });
  }
  async sendFeedback(params: any) {
    return await this.post("/send/feedback", params);
  }

  async getCardDetails(customerId: string, subscriptionId: string | null) {
    let url = `/getAllCards?cust_id=${customerId}`;
    if (subscriptionId) {
      url += `&sub_id=${subscriptionId}`;
    }
    return await this.get(url);
  }

  async getPaypalSubscriptionDetails(subscriptionId: string | null) {
    let url = `/getPaypalSubscriptionDetails?sub_id=${subscriptionId}`;
    return await this.get(url);
  }

  async updateEmail(payload: object) {
    return await this.post(`/updateEmail`, payload);
  }

  async getUser(userId: number | string = "current") {
    return await this.get(`/user/${userId}`);
  }
  async cancelAccount(userId: number | string = "current") {
    return await this.get(`/user/${userId}/cancel`);
  }
  async getUserReferrals(
    userId: number | string = "current",
    tier: string = ""
  ) {
    return await this.get(`/user/${userId}/referrals?tier=${tier}`);
  }
  async getUserChartReferrals(
    userId: number | string = "current",
    tier: string = ""
  ) {
    return await this.get(`/user/${userId}/chartReferrals?tier=${tier}`);
  }
  async getAffiliatePlansList() {
    return await this.get(`/get_affiliate_plans`);
  }
  async createAffiliatePlan(payload: object) {
    return await this.post(`/create_affiliate_plan`, payload);
  }
  async updateAffiliatePlan(payload: object) {
    return await this.post(`/update_affiliate_plan`, payload);
  }
  async getAdminDashboardRecords(params: any) {
    return await this.get(
      `/getAdminDashboardRecords?startDate=${params.startDate}&endDate=${params.endDate}`
    );
  }
  async getMedia(params?: object) {
    return await this.get("/media", {
      params: params,
    });
  }

  async startCheckout(plan: string, payload: any) {
    return await this.post("/checkout/" + plan, payload);
  }

  async oneTimeCheckout(payload: any) {
    return await this.post("/checkout_one_time", payload);
  }

  async trialCheckout(plan: string, data: any) {
    return await this.post("/checkout/trial/" + plan, data);
  }

  async getLabels(params: LabelsParams) {
    return await this.get("/labels", {
      params: params,
    });
  }

  async getSessions(filter?: "purchased" | "authored") {
    return await this.get(filter ? `/session?filter=${filter}` : "/session");
  }
  async getAllSessions() {
    return await this.get("/getAllSessions");
  }

  async addTokenPlayerSession(userId: number, payload: any) {
    return await this.post(`/save_token/${userId}`, payload);
  }

  async updateUser(id: number | string, params: object) {
    return await this.put("/user/" + id, { user: params });
  }
  async updateLastLogin(email: string) {
    return await this.post("/update_lastlogin/" + email);
  }
  async updateSession(sessionId: number, session: any) {
    return await this.post("/update/session/" + sessionId, session);
  }

  async getSessionById(id: number) {
    return await this.get("/session/" + id);
  }

  async createSurvey(sessionId: number, emotion: string) {
    return await this.post(`/session/${sessionId}/survey`, {
      beforeEmotion: emotion,
    });
  }
  async answerSurvey(sessionId: number, surveyId: string, emotion: string) {
    return await this.post(`/session/${sessionId}/survey/${surveyId}`, {
      afterEmotion: emotion,
    });
  }

  async uploadFiles(
    files: FileList,
    id: number | null,
    successCount: any,
    type: "img" | "aux" | "vid",
    purpose: "session" | "profile" | "thumbnail" | "coverphoto",
    sessionId: number | null
  ) {
    const promises: Promise<APIResponse<any>>[] = [];
    for (let i = 0; i < files.length; ++i) {
      let newParmas: UploadParams = {
        mime: files[i].type,
        filename: files[i].name,
        type: type,
        purpose: purpose,
        user: id,
        session: sessionId,
      };
      promises.push(this.uploadFile(files[i], newParmas, successCount));
    }
    return await Promise.all(promises);
  }
  async uploadFile(file: File, params: UploadParams, successCount: any) {
    const upload = await this.post("/upload", params);

    return await this.put(get(upload.payload, "upload_url"), file, {
      headers: { "content-type": params.mime },
    })
      .then((res) => {
        successCount(new Date().getTime());
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async uploadAudioFiles(files: FileList, successCount: any) {
    const promises: Promise<APIResponse<any>>[] = [];
    for (let i = 0; i < files.length; ++i) {
      let newParmas: UploadAudioParams = {
        mime: files[i].type,
        fileName: files[i].name,
        fileType: files[i].name.split(".")[1],
        fileSize: files[i].size,
      };
      promises.push(this.uploadAudioFile(files[i], newParmas, successCount));
    }
    return await Promise.all(promises);
  }

  async uploadAudioFile(
    file: File,
    params: UploadAudioParams,
    successCount: any
  ) {
    const upload = await this.post("/upload_audio", params);
    return await this.put(get(upload.payload, "upload_url"), file, {
      headers: { "content-type": params.mime },
    })
      .then((res) => {
        successCount(new Date().getTime());
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async deleteMedia(id: number) {
    return await this.delete("/media/" + id);
  }

  async deleteMultipleMedia(payload: any) {
    return await this.post("/media_delete_multiple", payload);
  }

  async get<T>(
    endpoint: string,
    config?: AxiosRequestConfig
  ): Promise<APIResponse<T>> {
    return await this.makeCall<T>("GET", endpoint, config);
  }
  async post<T>(
    endpoint: string,
    payload?: any,
    config?: AxiosRequestConfig
  ): Promise<APIResponse<T>> {
    return await this.makeCall<T>("POST", endpoint, config, payload);
  }
  async put<T>(
    endpoint: string,
    payload: any,
    config?: AxiosRequestConfig
  ): Promise<APIResponse<T>> {
    return await this.makeCall<T>("PUT", endpoint, config, payload);
  }
  async delete<T>(
    endpoint: string,
    config?: AxiosRequestConfig
  ): Promise<APIResponse<T>> {
    return await this.makeCall<T>("DELETE", endpoint, config);
  }

  async makeCall<T>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    endpoint: string,
    config?: AxiosRequestConfig,
    payload?: any
  ): Promise<APIResponse<T>> {
    let response: AxiosResponse | null = null;

    try {
      switch (method) {
        case "DELETE":
          response = await this.axios.delete(endpoint, config);
          break;

        case "POST":
          response = await this.axios.post(endpoint, payload, config);
          break;

        case "PUT":
          response = await this.axios.put(endpoint, payload, config);
          break;

        case "GET":
        default:
          response = await this.axios.get(endpoint, config);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        switch (err.response.data) {
          case "SESSION_EXPIRED":
            Firebase.Auth.onAuthStateChanged(async (authUser) => {
              if (authUser) {
                const token = await authUser.getIdToken();
                if (token) {
                  await set(
                    APIAxios.defaults.headers,
                    "authorization",
                    `Bearer ${token}`
                  );
                  window.location.reload();
                }
              } else {
                window.location.reload();
              }
            });
            break;
          //   const token = await Firebase.Auth.currentUser?.getIdToken();
          //   if (token) {
          //     await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          //     window.location.reload()
          // }
          // break;
          case "BAD_CONNECTION":
          case "UNCAUGHT_ERROR":
          case "DATABASE_LIMIT_EXCEEDED":
            localStorage.setItem("service_unavailable_status", "failed");
            await Firebase.Auth.signOut();
            window.location.href = "/error";
            break;
          default:
            return {
              successful: false,
              status: err.response ? err.response.status || 500 : 500,
              message: err.response
                ? err.response.statusText || "Unknown Error"
                : "Unknown Error",
              payload: err.response ? err.response.data || null : null,
            };
        }
      } else {
        return {
          successful: false,
          status: err.response ? err.response.status || 500 : 500,
          message: err.response
            ? err.response.statusText || "Unknown Error"
            : "Unknown Error",
          payload: err.response ? err.response.data || null : null,
        };
      }
    }

    if (!response)
      return {
        successful: false,
        status: 500,
        message: "Unknown Error",
        payload: null,
      };

    return {
      successful: true,
      status: response.status,
      message: response.statusText,
      payload: response.data,
    };
  }

  async getWatchAnalytics(params?: WatchAnalyticsParams) {
    return await this.get("/watch/analytics", {
      params: params,
    });
  }

  async getReferalAnalysis(userId: number | string = "current") {
    return await this.get(`/user/${userId}/referral_codes`);
  }

  async getVisitCounts(params?: VisitCountsParams) {
    return await this.get("/visit_counts", {
      params: params,
    });
  }

  async getLedgerData(
    userId?: any,
    eligibleBox?: number,
    payoutTransactionDate?: string
  ) {
    let id = userId && userId != null ? userId : "";
    return await this.get(
      `/ledgerEntries?userId=${id}&eligible=${eligibleBox}&payoutTransactionDate=${payoutTransactionDate}`
    );
  }

  async postLedgerData(params?: addLedgerParams) {
    return await this.post("/addledgerEntry", {
      data: params,
    });
  }
  async postLedgerPayoutData(params?: any) {
    return await this.post("/addledgerPayoutEntry", {
      data: params,
    });
  }
  async deleteLedgerData(ledgerId: number) {
    await this.delete(`/deleteledgerEntry/${ledgerId}`);
  }
  async getLedgerPayoutData() {
    return await this.get("/ledgerPayouts");
  }

  async getAllUsers() {
    return await this.get("/userManagement");
  }

  async updateInviteRoles(payload: object) {
    return await this.put("/updateInviteRoles", payload);
  }

  async getUsers() {
    return await this.get("/getAllUsers");
  }

  async getAllTimeUsers() {
    return await this.get("/getAllTimeUsers");
  }

  async getThreeMonthsUsers() {
    return await this.get("/getThreeMonthUsers");
  }

  async getInviteReport() {
    return await this.get("/getInviteReport");
  }

  async getAllTimeSignedupUsers() {
    return await this.get("/getAllTimeSignedupUsers");
  }

  async getSignupedActiveUsers() {
    return await this.get("/getSignupedActiveUsers");
  }

  async getMembershipTypeReport() {
    return await this.get("/getMembershipTypeReport");
  }

  async updateUserInfo(payload: object) {
    return await this.post("/update_user", payload);
  }

  async getUserFavorites(userId: string | number) {
    return await this.get(`/get_favorite_sessions?userId=${userId}`);
  }

  async favoriteSession(state: string, payload: any) {
    return await this.post(`/favorite_session?state=${state}`, payload);
  }

  async getRecentSessions(userId: string | number) {
    return await this.get(`/recent_sessions?userId=${userId}`);
  }

  async updateTutorialStatus(userId: number) {
    return await this.post(`/update_tutorial_status?userId=${userId}`);
  }

  async getSessionImages(sessionId: number) {
    return await this.get(`/get_session_images?sessionId=${sessionId}`);
  }

  async getChildUsers(parentId: number) {
    return await this.get(`/get_viewing_permission?parentId=${parentId}`);
  }

  async giftSession(payload: object) {
    return await this.post(`/gift_session`, payload);
  }

  async deleteGift(relationshipId: number) {
    return await this.delete(`/delete_gift?relationship_id=${relationshipId}`);
  }

  async getAudioList() {
    return await this.get(`/get_audio_list`);
  }

  async removeAudio(payload: object) {
    return await this.post(`/remove_audio`, payload);
  }

  async updateAudio(payload: object) {
    return await this.post(`/update_audio`, payload);
  }

  async getReferredUsers(userId: number) {
    return await this.get(`/get_referred_users?userId=${userId}`);
  }

  async getPersonalizedImages(userId: number) {
    return await this.get(`/get_personalized_images?user_id=${userId}`);
  }

  async saveGeneratedZips(payload: object) {
    return await this.post(`/save_generated_zips`, payload);
  }

  async getGeneratedZips() {
    return await this.get(`/get_generated_zips`);
  }

  async getGeneratedZipsById(sessionId: number) {
    return await this.get(`/get_generated_zips?sessionId=${sessionId}`);
  }

  async removeGeneratedZips(payload: object) {
    return await this.post(`/remove_zip_files`, payload);
  }

  async updateSessionInfo(payload: object) {
    return await this.post(`/update_session_info`, payload);
  }

  async createSession(payload: object) {
    return await this.post(`/session`, payload);
  }

  async assignChildSessions(payload: object) {
    return await this.post(`/assign_child_sessions`, payload);
  }

  async getCategories() {
    return await this.get(`/get_categories`);
  }

  async addCategories(payload: object) {
    return await this.post(`/add_category`, payload);
  }

  async deleteCategory(payload: object) {
    return await this.post(`/delete_category`, payload);
  }

  async updateSessionCategory(payload: object) {
    return await this.post(`/update_session_category`, payload);
  }

  async getUserHistory(payload: object) {
    return await this.post(`/view_user_activity`, payload);
  }

  async setDiplayOrder(payload: object) {
    return await this.post(`/update_display_order`, payload);
  }

  async registerUserInfusionsoft(payload: object) {
    return await this.post(`/register_user_infusionsoft`, payload);
  }

  async getCoachingSessions(days: number) {
    return await this.get(`/get_coaching_sessions?days=${days}`);
  }

  async updateCoachingStatus(payload: any) {
    return await this.post(`/update_coaching_start`, payload);
  }

  async likeSession(payload: any, likeStatus: string) {
    return await this.post(`/likeSession?likeStatus=${likeStatus}`, payload);
  }

  async getlikeSession() {
    return await this.get(`/getlikeSession`);
  }

  async createCoachingSession(payload: any) {
    return await this.post(`/create_coaching_session`, payload);
  }
  async getDays() {
    return await this.get(`/get_Alldays`);
  }
  async uploadThumbnail(
    file: File,
    params: UploadThumbnailParams,
    successCount: any
  ) {
    let url;
    if (params.status === "remove" && params.thumbnailpath) {
      url =
        process.env.REACT_APP_MEDIA_URL +
        `/uploadThumbnail?id=${params.id}&filename=${params.filename}&mime=${
          params.mime.split("/")[1]
        }&status=${
          params.status
        }&thumbnailpath=${params.thumbnailpath.substring(1)}`;
    } else {
      url =
        process.env.REACT_APP_MEDIA_URL +
        `/uploadThumbnail?id=${params.id}&filename=${params.filename}&mime=${
          params.mime.split("/")[1]
        }`;
    }
    return await this.put(url, file, {
      headers: { "content-type": params.mime },
    })
      .then((res) => {
        successCount(new Date().getTime());
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  async getAllCoachingSessions() {
    return await this.get(`/getCoachingSessions`);
  }
  async updateCoachingSessionInfo(payload: object) {
    return await this.post("/update_coaching_session", payload);
  }
  async day30ChallengeToUpgradeUser(payload: object) {
    return await this.post("/update_role_30_challenge_upgrade", payload);
  }
  async day30ChallengeToUpgradeUpdate(payload: object) {
    return await this.post("/reset_day_30_challenge_upgrade", payload);
  }

  async day365ChallengeUser(payload: object) {
    return await this.post("/update_role_365_challenge_upgrade", payload);
  }

  async day365ChallengeUserUpdate(payload: object) {
    return await this.post("/reset_day_365_challenge_upgrade", payload);
  }

  async retriveUpcomingInvoice(payload: object) {
    return await this.post("/retrive_upcoming_invoice", payload);
  }
  async prorationSubscriptionUpdate(payload: object) {
    return await this.post("/proration_subscription_update", payload);
  }

  getImageSession(userId: any, sessionId: any) {
    return this.get(`/api/media_session/${sessionId}?user_id=${userId}`);
  }

  getDefaultRequiredImages() {
    return this.get(`/api/default_required_images?set_name=set_2`);
  }
  async trialPaypalCheckout(plan: string, data: any) {
    return await this.post("/paypal_checkout/trial/" + plan, data);
  }

  async deleteUser(userId: string = "current") {
    return await this.delete(`user/${userId}`);
  }

  withdrawTokens(userId: number | string = "current", payload: any) {
    return this.post(`/user/${userId}/withdraw-token`, payload);
  }

  getUserTokenVault(userId: number | string = "current") {
    return this.get(`/user/${userId}/token-vault`);
  }
}
