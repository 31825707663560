import Modal from 'react-bootstrap/Modal';
import React from 'react';
import clsx from 'clsx';
function BasicModal(props){

    const modalProps = props.modalProps || {}; 
    return(
        <Modal show={props.show} onHide={props.onClose} {...modalProps} dialogClassName={'x-modal'} contentClassName={'x-modal__content'}>
          <Modal.Header closeButton>
            <div className={clsx('x-modal__header')}>
                <Modal.Title className={clsx('x-modal__header-title', 'main-title','b-text')}>{props.title}</Modal.Title>
                <p className={clsx('x-modal__header-subtitle','p-text')}>{props.subtitle || ''} </p>
            </div>
          </Modal.Header>

          <Modal.Body>
            {props.body}
          </Modal.Body>
        </Modal>
    )
}

export default BasicModal;