// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/tutorial_back.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tutorial_div {\n  background: white;\n  margin-left: -30px !important;\n  margin-right: -30px;\n  margin-top: -20px;\n  margin-bottom: -10px; }\n  @media (max-width: 580px) {\n    .tutorial_div {\n      margin-left: 0 !important;\n      margin-right: 0;\n      margin-top: 0;\n      margin-bottom: 0; } }\n\n.top_row {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center fixed;\n  margin: 0 !important;\n  background-size: cover; }\n  .top_row h1 {\n    font-family: sofia-pro, sans-serif;\n    font-weight: bolder;\n    font-size: 61px;\n    line-height: 59px; }\n  .top_row p {\n    font-family: sofia-pro, sans-serif;\n    font-weight: 600;\n    font-size: 39px;\n    line-height: 46px; }\n\n.middle_row {\n  background: url(https://www.positiveprime.com/wp-content/uploads/2021/04/orange-gradient-bg.png) no-repeat center center fixed;\n  margin: 0 !important; }\n  .middle_row h2 {\n    font-family: sofia-pro, sans-serif;\n    font-weight: bolder;\n    font-size: 40px;\n    line-height: 59px;\n    color: black; }\n\n.embed-container {\n  position: relative;\n  padding-bottom: 56.25%;\n  height: 0;\n  overflow: hidden;\n  max-width: 100%; }\n\n.embed-container iframe,\n.embed-container object,\n.embed-container embed {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%; }\n\n.embed-container {\n  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);\n  border: 0.5rem solid white; }\n", ""]);
// Exports
module.exports = exports;
